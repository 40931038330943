import axios from "axios";
const BASE_URL_CF = process.env.VUE_APP_CF_D2C
const BASE_URL = process.env.VUE_APP_BASE_URL_API;
// const BASE_URL_CF_AUTOMARSH = process.env.VUE_APP_CF_AUTOMARSH

export const zoneCosts = async (zipCode) => {
    try {
       const {data} = await axios.get(BASE_URL_CF + '/AIG_D2C_CONDOMINIO_GET_ZONE?zipCode=' + zipCode)
       return data
    } catch (error) {
        throw error
    }
}


export const getPlans = async (planId, zona_hidro, zona_sismica, dataCustomer) => {
    try {
        const body = {
            planId, zona_hidro, zona_sismica, dataCustomer, companyId: "CONDOMINIO_ACAPULCO_DEV"
        }

       const {data} = await axios.post(BASE_URL_CF + '/AIG_D2C_CONDOMINIO_ACAPULCO_GET_PLAN_TEST', body)
      
       return data
    } catch (error) {
        throw error
    }
}

export const emiliPayments = async (body) => {
    try {
       const {data} = await axios.post(BASE_URL_CF + '/AIG_CONDOMINIO_PAYMENT_EMILI_PROCESS_PAYMENT_V3_TEST', body)
       return data
    } catch (error) {
        throw error
    }
}

export const convertToDollar = async () => {
    try {
        const response = await axios.get("https://openexchangerates.org/api/latest.json?app_id=a50fae975e3349f9bf6b1c480aeb5512");
        const values = response.data;
        const actualRateMXN = values.rates.MXN;
        return actualRateMXN;
    } catch (error) {
        throw error.message;
    }
}

export const getQuotePDF = async (quoteId,zipCode,catastrofico,companyId) => {
    try {
        return await axios.post(BASE_URL + '/_api/v1/quotes/downloadQuotePdfCondominio/',{quoteId,zipCode,catastrofico,companyId},{
        responseType: "blob",
      });
    } catch (error) {
        throw error
    }
}